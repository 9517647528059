import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./assets/page1.css";
import "./assets/style.css";
import "./assets/header.css";
import logo from "./images/bio_logo.png";
import { useHistory, Redirect, Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { uAPI, IP } from "./config/Config";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';

export default function Header() {
    const history = useHistory();
    const [state, setState] = React.useState();
    const [lastLocation, setLastLocation] = React.useState();
    const [loginName, setLoginName] = React.useState();
    const [loginPerusahaan, setLoginPerusahaan] = React.useState();
    const [loginBranch, setLoginBranch] = React.useState();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [lvl, setlvl] = React.useState();
    const [CSRFToken, setCSRFToken] = React.useState();

    const instance = axios.create({
        withCredentials: true,
    });

    useEffect(() => {
        //   console.log(history.location.state);

        if (CSRFToken === undefined) {
            getCSRFToken();
        }

        if (history.location.state === null && cookies["_login"] == undefined) {
            // setRedirctTo(true);
        } else if (history.location.state != null && cookies["_login"] == undefined) {
            // setRedirctTo(true);
        } else if (history.location.state == undefined && cookies["_login"] == undefined) {
            // setRedirctTo(true);
        } else if (
            (history.location.state === null || history.location.state === undefined) &&
            cookies["_login"] != undefined
        ) {
            setState(JSON.parse(localStorage.getItem("_log")));
            // setLastLocation(history.location.pathname);
            //  console.log(history.location.pathname);
            setLoginName(JSON.parse(localStorage.getItem("_log")).nama_user);
            setLoginPerusahaan(JSON.parse(localStorage.getItem("_log")).nama_perusahaan);
            setLoginBranch(JSON.parse(localStorage.getItem("_log")).branch);
        } else {
            setState(history.location.state);
            setLastLocation(history.location.pathname);
            //  console.log(history.location.pathname);
            setLoginName(history.location.state["detail_login"].nama_user);
            setLoginPerusahaan(history.location.state["detail_login"].nama_perusahaan);
            setLoginBranch(history.location.state["detail_login"].branch);
        }

        setTimeout(() => {
            if (localStorage._log) {
                setlvl(JSON.parse(localStorage._log).level);
            }
        }, 1000);
    });


    const getCSRFToken = async () => {
        await instance
            .get(uAPI + "/getCSRFToken")
            .then((response) => {
                // console.log(response);
                setCSRFToken(response.data.CSRFToken);
                instance.defaults.headers.post["X-CSRF-Token"] = response.data.CSRFToken;
                sendToken();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const sendToken = async (csrf) => {
        console.log("ini berjalan");
        await instance({
            method: "post",
            url: uAPI + "/api/v1/auth_csrf",
        })
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const goToPage1 = () => {
        history.push({
            pathname: "/pasien",
            state: state,
        });
    };

    const goToReg = () => {
        history.push({
            pathname: "/registration",
            state: state,
        });
    };

    const logout = () => {
        // instance({
        //  method: "post",
        //  url: uAPI + "/logout",
        // })
        //  .then((response) => {
        //   console.log(response);
        //  })
        //  .catch((error) => {
        //   console.log(error);
        //  });

        // removeCookie("_login");
        // removeCookie("_csrf");
        // removeCookie("XSRF-TOKEN");

        // const formData = new FormData();
        // const config = { headers: { 'content-type': 'multipart/form-data' } };
        // formData.append('id_user', JSON.parse(localStorage._log).id);
        // formData.append('name', JSON.parse(localStorage._log).nama_user);
        // formData.append('message', "Logout");
        // instance.defaults.headers.post["X-CSRF-Token"] = CSRFToken;
        // instance.post(uAPI + '/api/v1/log', formData, config)
        //     .then(response => {
        //         console.log(response)
        //         if (response.data.status === 406) {
        //             console.log(response)
        //         } else {
        //             console.log(response)
        //         }
        //     }).catch(function (error) { console.log(error) });



        Cookies.remove("_csrf", { path: '/', domain: '.biomedika.co.id' })
        Cookies.remove("_login", { path: '/', domain: '.biomedika.co.id' })
        localStorage.clear();
        sessionStorage.clear();

        history.push({
            pathname: "/",
            status: "logout",
            login_alert: {
                msg: "Berhasil Log Out",
            },
        });
    };

    return (
        <React.Fragment>
            <Helmet>
                <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
                <title>Bio Medika - Rujukan</title>
                <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
                <meta name="robots" content="noindex, nofollow" />
                <meta name="googlebot" content="noindex, nofollow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <body />
            </Helmet>
            <div>
                <nav
                    className="navbar navbar-icon-top navbar-expand-lg transparent navbar-inverse navbar-fixed-top"
                    style={{
                        backgroundColor: "rgba(0,0,0,0.0)",
                        fontFamily: "var(--font-family-raleway-bold)",
                    }}
                >
                    <a className="navbar-brand" href="#"></a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <img src={logo} width="200" alt="Logo" style={{ padding: 20 }} />
                        </ul>

                        {lvl != 1 ? (
                            <ul className="navbar-nav ">
                                {lastLocation == "/pasien" ? (
                                    <li className="nav-item">
                                        <div className="nav-link next-button-mini">
                                            <input type="button" name="" onClick={goToReg} value="Registrasi" />
                                        </div>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <div className="nav-link next-button-mini">
                                            <input type="button" name="" onClick={goToPage1} value="Cek Data" />
                                        </div>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        rootClose={true}
                                        overlay={
                                            <Popover
                                            //   style={{backgroundColor:'#CCD9DD'}}
                                            >
                                                <div style={{ margin: "10px" }}>
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontFamily: "var(--font-family-gotha-boo)",
                                                            fontSize: "12px",
                                                            // marginBottom: "10px",
                                                        }}
                                                    >
                                                        {loginName}{" "}
                                                    </p>
                                                    <p
                                                        style={{
                                                            // fontWeight: "bold",
                                                            fontFamily: "var(--font-family-gotha-boo)",
                                                            fontSize: "10px",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        {loginPerusahaan}{" "}
                                                    </p>
                                                    <div className="list-group text-center">
                                                        <div className="next-button-mini">
                                                            <input type="button" name="" onClick={logout} value="Log Out" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >
                                        <div className="nav-link back-button-mini">
                                            <input type="button" name="" value="Profile" />
                                        </div>
                                    </OverlayTrigger>
                                </li>
                                {/* <li className="nav-item">
          <a className="nav-link" href="#"></a>
         </li> */}
                            </ul>
                        )
                            : null

                        }


                        {lvl == 1 ? (
                            <ul className="navbar-nav ">
                                <li className="nav-item">
                                    <div className="nav-link next-button-mini">
                                        <input type="button" name="" value="Registrasi" />
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        rootClose={true}
                                        overlay={
                                            <Popover
                                            //   style={{backgroundColor:'#CCD9DD'}}
                                            >
                                                <div style={{ margin: "10px" }}>
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontFamily: "var(--font-family-gotha-boo)",
                                                            fontSize: "12px",
                                                            // marginBottom: "10px",
                                                        }}
                                                    >
                                                        {loginName}{" "}
                                                    </p>
                                                    <p
                                                        style={{
                                                            // fontWeight: "bold",
                                                            fontFamily: "var(--font-family-gotha-boo)",
                                                            fontSize: "10px",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        Bio Medika {loginBranch}{" "}
                                                    </p>
                                                    <div className="list-group text-center">
                                                        <div className="next-button-mini">
                                                            <input type="button" name="" onClick={logout} value="Log Out" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >
                                        <div className="nav-link back-button-mini">
                                            <input type="button" name="" value="Profile" />
                                        </div>
                                    </OverlayTrigger>
                                </li>
                            </ul>
                        )
                            : null

                        }

                    </div>
                </nav>
            </div>
        </React.Fragment>
    );
}
