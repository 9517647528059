import React, { Component, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { uAPI } from "./config/Config";
import Header from "./Header";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
    textFilter,
    dateFilter,
} from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory, Redirect } from "react-router-dom";

import moment from "moment";
import 'moment/locale/id';
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";
import QRCode from "qrcode.react";
import logo_ from "./images/Path 47.png";
import logo_48 from "./images/Path 48.png";
import logo_b from "./images/bio_logo.png";
import { useReactToPrint } from "react-to-print";

export default function Page1() {
    // state
    const [countLog, setCountLog] = React.useState(0);
    const [countPID, setCountPID] = React.useState(0);
    const [userID, setUserID] = React.useState();
    const [pasien, setPasien] = useState();
    const history = useHistory();
    const [redirctTo, setRedirctTo] = useState(false);
    const { SearchBar } = Search;
    const [cookies, setCookie] = useCookies();

    const [login, setLogin] = React.useState();
    const [loginName, setLoginName] = React.useState();
    const [branchName, setBranchName] = React.useState();
    const [perusahaan, setPerusahaan] = React.useState();
    const [perujuk, setPerujuk] = React.useState();
    const [kodePerusahaan, setKodePerusahaan] = React.useState();
    const [CSRFToken, setCSRFToken] = React.useState();

    const componentRef = React.useRef();
    const wrapper_ref = React.useRef();

    const INITIAL_STATE = {
        pid: "",
    };

    const [state, setState] = React.useState(INITIAL_STATE);
    const {
        pid,
    } = state;

    const instance = axios.create({
        withCredentials: true,
    });

    const [dPrint, setdPrint] = React.useState([]);

    const getCSRFToken = async () => {
        await instance
            .get(uAPI + "/getCSRFToken")
            .then((response) => {
                // console.log(response);
                setCSRFToken(response.data.CSRFToken);
                instance.defaults.headers.post["X-CSRF-Token"] = response.data.CSRFToken;
                sendToken();

                if (countLog == 0) {
                    if (userID) {
                        setCountLog(1);
                        const formData = new FormData();
                        const config = { headers: { 'content-type': 'multipart/form-data' } };
                        formData.append('id_user', userID);
                        formData.append('name', JSON.parse(localStorage._log).nama_user);
                        formData.append('message', "Akses Daftar Pasien");
                        instance.defaults.headers.post["X-CSRF-Token"] = response.data.CSRFToken;
                        instance.post(uAPI + '/api/v1/log', formData, config)
                            .then(response => {
                                console.log(response)
                                if (response.data.status === 406) {
                                    console.log(response)
                                } else {
                                    console.log(response)
                                }
                            }).catch(function (error) { console.log(error) });
                    }

                }

            })
            .catch((error) => {
                console.log(error);
            });
    };

    const sendToken = async (csrf) => {
        //  console.log(document.cookie);
        console.log(cookies);
        // instance.defaults.headers.post["X-CSRF-Token"] = CSRFToken;
        await instance({
            method: "post",
            url: uAPI + "/api/v1/auth_csrf",
        })
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    let nameFilter;

    useEffect(() => {

        if (CSRFToken === undefined) {
            getCSRFToken();
        }

        if (history.location.state === null && cookies["_login"] == undefined) {
            setRedirctTo(true);
        } else if (history.location.state != null && cookies["_login"] == undefined) {
            setRedirctTo(true);
        } else if (history.location.state == undefined && cookies["_login"] == undefined) {
            setRedirctTo(true);
        } else if (
            (history.location.state === null || history.location.state === undefined) &&
            cookies["_login"] != undefined
        ) {
            var bytes = CryptoJS.AES.decrypt(
                JSON.parse(localStorage.getItem("_log")).d_u,
                JSON.parse(localStorage.getItem("_log")).p_.slice(0, 6)
            );
            var originalText = bytes.toString(CryptoJS.enc.Utf8);

            if (originalText != undefined) {
                setUserID(originalText);
                if (countPID == 0) {
                    setLoginName(JSON.parse(localStorage.getItem("_log")).nama_user);
                    setBranchName(JSON.parse(localStorage.getItem("_log")).branch);
                    setPerusahaan(JSON.parse(localStorage.getItem("_log")).nama_perusahaan);
                    setPerujuk(JSON.parse(localStorage.getItem("_log")).nama_user);
                    setKodePerusahaan(JSON.parse(localStorage.getItem("_log")).kode_perusahaan);
                    setCountPID(1);
                }
            }

            if (pasien === undefined) {
                cek_all_data();
            }

        } else {
            var bytes = CryptoJS.AES.decrypt(
                history.location.state["detail_login"].d_u,
                history.location.state["detail_login"].p_.slice(0, 6)
            );
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            setLogin(history.location.state["login"]);
            localStorage.setItem(
                "_log",
                JSON.stringify(history.location.state["detail_login"])
            );

            console.log('originalText', originalText)

            if (originalText != undefined) {
                setUserID(originalText);
                if (countPID == 0) {
                    setLoginName(JSON.parse(localStorage.getItem("_log")).nama_user);
                    setBranchName(JSON.parse(localStorage.getItem("_log")).branch);
                    setPerusahaan(JSON.parse(localStorage.getItem("_log")).nama_perusahaan);
                    setPerujuk(JSON.parse(localStorage.getItem("_log")).nama_user);
                    setKodePerusahaan(JSON.parse(localStorage.getItem("_log")).kode_perusahaan);
                    setCountPID(1);
                }
            }

            if (pasien === undefined) {
                cek_all_data();
            }

        }
    });


    const ComponentToPrint = React.forwardRef((props, ref) => (
        //   <div ref={ref} className="hide-print container-fluid">
        <div
            ref={ref}
            className="print-only container-fluid"
            style={{
                maxWidth: "1240px",
                maxHeight: "874px",
            }}
        >
            <div id="ui-view" data-select2-id="ui-view">
                <div>
                    <div className="card">
                        {/* <div className="card-header">Invoice
      <strong>#BBB-10010110101938</strong>
      </div> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col=6" style={{ width: "50%" }}>
                                    <img
                                        src={logo_b}
                                        width="320"
                                        alt="Logo"
                                        style={{
                                            padding: 20,
                                            display: "block",
                                        }}
                                    />
                                </div>
                                <div className="col-6" style={{ display: "block", margin: "auto" }}>
                                    <p
                                        style={{
                                            float: "right",
                                            fontFamily: "var(--font-family-gotha-bol)",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {/* FRM/LAB/000;Rev.00 */}
                                    </p>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: "80px" }}>
                                <div style={{ margin: "auto" }}>
                                    <div className="col-12">
                                        <strong
                                            style={{
                                                fontSize: 38,
                                                fontFamily: "var(--font-family-gotham-black-regular)",
                                            }}
                                        >
                                            RINCIAN BIAYA
                                        </strong>
                                    </div>
                                </div>
                            </div>

                            <hr style={{ border: "0.5px solid #707070" }} />
                            <form
                                style={{
                                    padding: "0px 20px 0px 20px",
                                    fontFamily: "var(--font-family-gotha-boo)",
                                    marginTop: "24px",
                                }}
                            >
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label
                                            style={{
                                                lineHeight: "14px",
                                                fontSize: 24,
                                                textAlign: "left",
                                            }}
                                        >
                                            Nama Pasien
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <label
                                            style={{
                                                fontSize: 24,
                                                fontWeight: "500",
                                            }}
                                        >
                                            : {dPrint.nama_pasien}
                                        </label>
                                    </div>
                                </div>

                                {dPrint.no_telp ? (
                                    <div className="row mb-2" style={{ display: dPrint.no_telp.length < 5 ? 'none' : null }}>
                                        <div className="col-3">
                                            <label
                                                style={{
                                                    lineHeight: "14px",
                                                    fontSize: 24,
                                                    textAlign: "left",
                                                }}
                                            >
                                                No. Telp
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <label
                                                style={{
                                                    fontSize: 24,
                                                    fontWeight: "500",
                                                }}
                                            >
                                                : {dPrint.no_telp}
                                            </label>
                                        </div>
                                    </div>
                                ) : null}

                                {dPrint.email ? (
                                    <div className="row mb-2" style={{ display: dPrint.email.length < 5 ? 'none' : null }}>
                                        <div className="col-3">
                                            <label
                                                style={{
                                                    lineHeight: "14px",
                                                    fontSize: 24,
                                                    textAlign: "left",
                                                }}
                                            >
                                                Email
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <label
                                                style={{
                                                    fontSize: 24,
                                                    fontWeight: "500",
                                                }}
                                            >
                                                : {dPrint.email}
                                            </label>
                                        </div>
                                    </div>
                                ) : null}

                                {dPrint.alamat ? (
                                    <div className="row mb-2" style={{ display: dPrint.alamat.length < 5 ? 'none' : null }}>
                                        <div className="col-3">
                                            <label
                                                style={{
                                                    lineHeight: "14px",
                                                    fontSize: 24,
                                                    textAlign: "left",
                                                }}
                                            >
                                                Alamat
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <table>
                                                <tr>
                                                <td style={{ width: '10px' }}>
                                                :
                                                </td>
                                                <td>
                                                 <div style={{ width: '45vw' }}>
                                                        <label
                                                            style={{
                                                                fontSize: 24,
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                             {dPrint.alamat}
                                                        </label>
                                                    </div>
                                                </td>
                                                   
                                                </tr>
                                            </table>

                                        </div>
                                    </div>
                                ) : null}

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label
                                            style={{
                                                lineHeight: "14px",
                                                fontSize: 24,
                                                textAlign: "left",
                                            }}
                                        >
                                            No. Registrasi
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <label
                                            style={{
                                                fontSize: 24,
                                                fontWeight: "500",
                                            }}
                                        >
                                            : {dPrint.patient_id}
                                        </label>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label
                                            style={{
                                                lineHeight: "14px",
                                                fontSize: 24,
                                                textAlign: "left",
                                            }}
                                        >
                                            Perujuk
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <label
                                            style={{
                                                fontSize: 24,
                                                fontWeight: "500",
                                            }}
                                        >
                                            : {dPrint.nama_user}
                                        </label>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label
                                            style={{
                                                lineHeight: "14px",
                                                fontSize: 24,
                                                textAlign: "left",
                                            }}
                                        >
                                            Instansi Perujuk
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <label
                                            style={{
                                                fontSize: 24,
                                                fontWeight: "500",
                                            }}
                                        >
                                            : {dPrint.nama_perusahaan}
                                        </label>
                                    </div>
                                </div>


                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label
                                            style={{
                                                lineHeight: "14px",
                                                fontSize: 24,
                                                textAlign: "left",
                                            }}
                                        >
                                            Status Bayar
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <label
                                            style={{
                                                fontSize: 24,
                                                fontWeight: "500",
                                            }}
                                        >
                                            : {dPrint.status_pembayaran}
                                        </label>
                                    </div>
                                </div>

                                <div className="row mb-2" style={{ display: dPrint.catatan == "" || dPrint.catatan == null ? 'none' : null }} >
                                    <div className="col-3">
                                        <label
                                            style={{
                                                lineHeight: "14px",
                                                fontSize: 24,
                                                textAlign: "left",
                                            }}
                                        >
                                            Catatan
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <label
                                            style={{
                                                fontSize: 24,
                                                fontWeight: "500",
                                            }}
                                        >
                                            : {dPrint.catatan}
                                        </label>
                                    </div>
                                </div>

                                <div className="row mb-2" style={{ marginBottom: "-4px", display: dPrint.diagnosis_klinis == "" || dPrint.diagnosis_klinis == null ? 'none' : null }} >
                                    <div className="col-3">
                                        <label
                                            style={{
                                                lineHeight: "14px",
                                                fontSize: 24,
                                                textAlign: "left",
                                            }}
                                        >
                                            Diagnosis Klinis
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <label
                                            style={{
                                                fontSize: 24,
                                                fontWeight: "500",
                                            }}
                                        >
                                            : {dPrint.diagnosis_klinis}
                                        </label>
                                    </div>
                                </div>

                                {/* <div className="row mb-2">

                                    <div
                                        style={{
                                            position: "absolute",
                                            right: "36px",
                                            top: "278px",
                                        }}
                                    >
                                        {dPrint.patient_id ? (<QRCode
                                            id="qr-gen"
                                            value={String(dPrint.patient_id)}
                                            size={160}
                                            level={"H"}
                                            fgColor="#22485e"
                                            imageSettings={{
                                                src: logo_,
                                                width: 30,
                                                height: 30,
                                                excavate: true,
                                            }}
                                        />) : null}

                                    </div>

                                </div> */}
                            </form>

                            <div className="row" style={{ marginTop: "60px" }}>
                                <div className="col-lg-12 col-sm-12 ml-auto">
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            marginBottom: "10px",
                                            fontFamily: "var(--font-family-gotha-bol)",
                                            marginLeft: "10px",
                                            fontSize: 24,
                                        }}
                                    >
                                        PEMERIKSAAN
                                    </p>
                                    <table className="table table-clear noborder">
                                        <tbody>
                                            {dPrint.checkout ? (
                                                JSON.parse(dPrint.checkout).map((key, index) => (
                                                    <tr key={"aCdRyLsIat" + index}>
                                                        <td className="left">
                                                            <p
                                                                style={{
                                                                    fontFamily: "var(--font-family-gotha-boo)",
                                                                    fontSize: 24,
                                                                    width: "400px",
                                                                    wordBreak: "break-all",
                                                                }}
                                                            >
                                                                {key["desc"]}
                                                            </p>
                                                        </td>
                                                        <td className="right">
                                                            <div className="row ml-1">
                                                                <div
                                                                    style={{
                                                                        fontFamily: "var(--font-family-gotha-boo)",
                                                                        fontSize: 24,
                                                                        marginLeft: "220px",
                                                                    }}
                                                                >
                                                                    Rp.
                                                                </div>
                                                                <div
                                                                    className="ml-auto mr-3"
                                                                    style={{
                                                                        fontFamily: "var(--font-family-gotha-boo)",
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    {numberFormat(key["price"]).replace("Rp", "")}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : null
                                            }

                                            {dPrint.checkout ? (
                                                <tr>
                                                    <td className="left">
                                                        <p style={{ fontFamily: "var(--font-family-gotha-bol)", fontSize: 24, }}>
                                                            Total Pemeriksaan
                                                        </p>
                                                    </td>
                                                    <td className="right" style={{ fontWeight: "bold" }}>
                                                        <div className="row ml-1">
                                                            <div
                                                                style={{
                                                                    fontFamily: "var(--font-family-gotha-boo)",
                                                                    fontSize: 24,
                                                                    marginLeft: "220px",
                                                                }}
                                                            >
                                                                Rp.
                                                            </div>
                                                            <div
                                                                className="ml-auto mr-3"
                                                                style={{
                                                                    fontFamily: "var(--font-family-gotha-boo)",
                                                                    fontSize: 24,
                                                                }}
                                                            >
                                                                {numberFormat(JSON.parse(dPrint.checkout).reduce(function (sum, item) {
                                                                    return sum + item.price;
                                                                }, 0)).replace("Rp", "")}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : null
                                            }

                                        </tbody>
                                    </table>
                                </div>

                                <div
                                    className="col-lg-12 col-sm-12 ml-auto"
                                    style={{ paddingLeft: "42px" }}
                                >
                                    {updateSKlist.length > 0 ? (
                                        <p
                                            style={{
                                                color: "#1C4D6A",
                                                fontWeight: "bold",
                                                marginBottom: "4px",
                                                fontFamily: "var(--font-family-raleway-bold)",
                                                fontSize: 20,
                                            }}
                                        >
                                            Syarat & Ketentuan
                                        </p>
                                    ) : null}

                                    <ul
                                        style={{
                                            listStyleType: "number",
                                            lineHeight: "22px",
                                            padding: "0px 0px 0px 12px",
                                            fontFamily: "var(--font-family-gotha-bol)",
                                            fontSize: 20,
                                        }}
                                    >
                                        {updateSKlist.map((key, index) => (
                                            <li key={"aCdRyLsIat" + index}>
                                                {key["desc"]}
                                                <ul>
                                                    {key["sk"].length > 0
                                                        ? key["sk"].map((item, index) => {
                                                            return <li style={{ fontSize: 18, }} key={"aCdRyLsIat" + Math.random()}>- {item}</li>;
                                                        })
                                                        : null}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ));

    const goPrint = (row) => {
        console.log("ini hasil print", row);
        handleSK(row);
        setdPrint(row);
        setTimeout(() => {
            handlePrint();
        }, 1000);

    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const downloadQRCode = (id) => {


        let myID = "qr-gen-download" + id;
        const canvas = document.getElementById(id);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download =
            "QR-" +
            `${id}-` +
            (Math.random() + 1).toString(36).substring(7).toUpperCase() +
            ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);


    };

    const shareQRCode = (id) => {
        let myID = "qr-gen-download" + id;
        const canvas = document.getElementById(id);
        let qrname = "QR-" +
            `${id}-` +
            (Math.random() + 1).toString(36).substring(7).toUpperCase() +
            ".png";
        canvas.toBlob(function (blob) {
            console.log("INI BLOB", blob);
            const file = new File([blob], qrname, { type: blob.type });
            console.log(file);
            if (navigator.share) {
                navigator.share({
                    title: "RUJUKAN BIO MEDIKA",
                    text: "Terimakasih, Anda berhasil registrasi rujukan Bio Medika, selanjutnya tunjukan QR ke petugas kami saat registrasi.",
                    url: "url to share",
                    files: [file],
                })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error in sharing', error));
            } else {
                console.log(`system does not support sharing files.`);
            }

        }, 'image/png');
    };


    const numberFormat = (value) =>
        new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);

    const columns = [
        {
            dataField: "patient_id",
            text: "NO. REG",
            headerStyle: (colum, colIndex) => {
                return { width: "100px", fontSize: 12 };
            },
            // style: (cell, row, rowIndex) => {

            //     if (row.status == "SELESAI") {
            //         return {
            //             backgroundColor: "#bae1ff"
            //         };
            //     } else { }

            // },
            filter: textFilter({
                delay: 1000, // default is 500ms
                style: {
                    marginTop: "10px",
                    marginLeft: "10px",
                    fontSize: 12
                },
                className: "test-classname",
                placeholder: "Scan QR disini...",
                // onClick: e => console.log(e)
            }),
            sort: true,
        },
        {
            dataField: "nama_pasien",
            text: "NAMA",
            headerStyle: (colum, colIndex) => {
                return { width: "180px", fontSize: 12 };
            },
            filter: textFilter({
                delay: 1000, // default is 500ms
                style: {
                    marginTop: "10px",
                    marginLeft: "10px",
                    fontSize: 12
                },
                className: "test-classname",
                placeholder: "Cari Nama...",
                // onClick: e => console.log(e)
            }),
            style: (cell, row, rowIndex) => {
                return {
                    width: "20px",
                    // textAlign: 'center',
                };
            },
            sort: true,
        },
        {
            dataField: "tgl_lahir",
            text: "TANGGAL LAHIR",
            headerStyle: (colum, colIndex) => {
                return { width: "60px", paddingBottom: '24px', fontSize: 12 };
            },
            style: (cell, row, rowIndex) => {
                return {
                    width: "20px",
                    textAlign: 'center',
                };
            },
            formatter: (cell, row, rowIndex) => {
                return (
                    <p>{moment(row.tgl_lahir, 'DD-MM-YYYY').format("DD MMMM YYYY")}</p>
                )
            },

            //  sort: true,
        },

        {
            dataField: "created_date",
            text: "TANGGAL RUJUKAN",
            headerStyle: (colum, colIndex) => {
                return { width: "80px", paddingBottom: '24px', fontSize: 12 };
            },
            style: (cell, row, rowIndex) => {
                return {
                    width: "20px",
                    textAlign: 'center',
                };
            },
            formatter: (cell, row, rowIndex) => {
                return (
                    <p>{moment(row.created_date).format("DD MMMM YYYY - hh:mm")}</p>
                )
            },

            //  sort: true,
        },

        {
            dataField: "status",
            text: "STATUS",
            headerStyle: (colum, colIndex) => {
                return { width: "40px", paddingBottom: '24px', fontSize: 12, textAlign: 'center', };
            },
            style: (cell, row, rowIndex) => {

                if (row.type == 1) {
                    return {
                        backgroundColor: "#006996",
                        color: '#fff',
                        textAlign: 'center',
                    };
                } else if (row.status == "SELESAI") {
                    return {
                        backgroundColor: "#266d57",
                        color: '#fff',
                        textAlign: 'center',
                    };
                } else {
                    return {
                        backgroundColor: "#f44336",
                        color: '#fff',
                        textAlign: 'center',
                    };
                }

            },
            formatter: (cell, row, rowIndex) => {
                if (row.type == 1) {
                    return (
                        <p>SAMPLE</p>
                    )
                } else if (row.status == "SELESAI") {
                    return (
                        <p>SELESAI</p>
                    )
                } else {
                    return (
                        <p>BELUM DIPROSES!</p>
                    )
                }

            },
            // filter: textFilter({
            //     delay: 1000, // default is 500ms
            //     style: {
            //         marginTop: "10px",
            //         marginLeft: "10px",
            //         fontSize: 12
            //     },
            //     className: "test-classname",
            //     placeholder: "",
            //     // onClick: e => console.log(e)
            // }),
            // sort: true,
        },
        // {
        //     dataField: "nama_user",
        //     text: "PERUJUK",
        //     headerStyle: (colum, colIndex) => {
        //         return { width: "100px", paddingBottom: '24px' };
        //     },
        //     style: (cell, row, rowIndex) => {
        //         return {
        //             width: "20px",
        //             // textAlign: 'center',
        //         };
        //     },

        //     //  sort: true,
        // },
        // {
        //     dataField: "nama_perusahaan",
        //     text: "INSTANSI",
        //     headerStyle: (colum, colIndex) => {
        //         return { width: "100px", paddingBottom: '24px' };
        //     },
        //     style: (cell, row, rowIndex) => {
        //         return {
        //             width: "20px",
        //             // textAlign: 'center',
        //         };
        //     },

        //     //  sort: true,
        // },
        //   {
        //    dataField: "no_telp",
        //    text: "NO. Telp",
        //    headerStyle: (colum, colIndex) => {
        //     return { width: "160px", textAlign: "center", lineHeight: "18px" };
        //    },
        //    //  sort: true,
        //   },
        //   {
        //    dataField: "alamat",
        //    text: "Alamat",
        //    //  sort: true,
        //   },
        //   {
        //    dataField: "email",
        //    text: "Email",
        //    //  sort: true,
        //   },
        //   {
        //    dataField: "tgl_periksa",
        //    text: "Tanggal Periksa",

        //    //    filter: dateFilter({
        //    //     getFilter: (filter) => {
        //    //       stockDateFilter = filter;
        //    //     }
        //    //   })

        //    filter: dateFilter({
        //     delay: 400,
        //     placeholder: "custom placeholder",
        //     withoutEmptyComparatorOption: true,
        //     // comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
        //     style: {
        //      display: "inline-block",
        //     },
        //     // className: 'custom-datefilter-class',
        //     comparatorStyle: { backgroundColor: "antiquewhite", marginTop: "10px" },
        //     //    comparatorClassName: "form-control rounded-pill",
        //     dateStyle: { marginTop: "10px", width: "80%" },
        //     //    dateClassName: "form-control rounded-pill",
        //    }),

        //    sort: true,

        //    //    filter: textFilter({
        //    //     delay: 1000, // default is 500ms
        //    //     style: {
        //    //      //   backgroundColor: 'yellow',
        //    //      marginTop: "10px",
        //    //     },
        //    //     className: "test-classname",
        //    //     placeholder: "",
        //    //     // onClick: e => console.log(e)
        //    //    }),
        //   },
        //   {
        //    dataField: "total_harga",
        //    text: "Total Harga",
        //    headerStyle: (colum, colIndex) => {
        //     return { width: "140px", textAlign: "center", lineHeight: "18px" };
        //    },
        //    //  sort: true,
        //   },
    ];

    const [sk, setSK] = useState();
    const [updateSKlist, setUpdateSKlist] = useState([]);
    let sk_ = [];
    let updateSKlist_ = [];

    const handleSK = (row) => {
        sk_ = [];
        updateSKlist_ = [];
        let pemeriksaan = JSON.parse(row.test);
        Object.keys(pemeriksaan).map(function (key) {
            let x = pemeriksaan[key];
            x.map(function (key, i) {
                if (key.status === true) {
                    sk_.push({
                        desc: key.desc,
                        sk: key.sk,
                    });
                }
            });
        });
        // console.log("ini SK", sk_);
        updateSK();
    };

    const updateSK = () => {
        setSK(sk_);
        instance.defaults.headers.post["X-CSRF-Token"] = CSRFToken;
        instance
            .get(uAPI + "/api/v1/syaratKetentuan")
            .then((response) => {
                response.data.results.map(function (key) {
                    let z = "";
                    let x = [];
                    sk_.map(function (obj, i) {
                        if (obj.sk !== null) {
                            if (obj.sk.includes(key.id_sk) === true) {
                                x.push(obj.desc);
                                z = key.desc;
                            }
                        } else {
                        }
                    });
                    if (x.length > 0) {
                        updateSKlist_.push({
                            desc: z,
                            sk: x,
                        });
                    }
                });
                setUpdateSKlist(updateSKlist_);
                console.log("ini SK asli", updateSKlist_);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleClick = () => {
        nameFilter("");
        // priceFilter('');
        // stockDateFilter();
    };

    const defaultSorted = [
        {
            dataField: "patient_id",
            order: "desc",
        },
    ];


    const expandRow = {
        // parentClassName: "expand-cellheader",
        className: 'expand-cell',
        renderer: (row) => (
            <div style={{ marginTop: "4px", marginLeft: "10px", width: '460px' }}>

                <table style={{ border: '1px solid #ddd', backgroundColor: '#a0d8d8', marginBottom: 10 }}>
                    {row.alamat ? (
                        <tr style={{ display: row.alamat.length < 5 ? 'none' : null }}>
                            <th>ALAMAT:</th>
                            <th><p style={{ fontWeight: 'bold' }}>{row.alamat}</p></th>
                        </tr>
                    ) : null}

                    {row.no_telp ? (
                        <tr style={{ display: row.no_telp.length < 5 ? 'none' : null }}>
                            <th>NO. TELP:</th>
                            <th><p style={{ fontWeight: 'bold' }}>{row.no_telp}</p></th>
                        </tr>
                    ) : null}

                    {row.email ? (
                        <tr style={{ display: row.email.length < 5 ? 'none' : null }}>
                            <th>EMAIL:</th>
                            <th><p style={{ fontWeight: 'bold' }}>{row.email}</p></th>
                        </tr>
                    ) : null}


                    <tr>
                        <th>PERUJUK:</th>
                        <th><p style={{ fontWeight: 'bold' }}>{row.nama_user}</p></th>
                    </tr>
                    <tr>
                        <th>INSTANSI PERUJUK:</th>
                        <th><p style={{ fontWeight: 'bold' }}>{row.nama_perusahaan}</p></th>
                    </tr>
                    <tr>
                        <th>STATUS:</th>
                        <th><p style={{ fontWeight: 'bold' }}>{row.status_pembayaran}</p></th>
                    </tr>
                    <tr style={{ display: row.catatan == null ? 'none' : null }}>
                        <th>CATATAN:</th>
                        <th><p style={{ fontWeight: 'bold' }}>{row.catatan}</p></th>
                    </tr>
                    <tr style={{ display: row.diagnosis_klinis == null ? 'none' : null }}>
                        <th>DIAGNOSIS KLINIS</th>
                        <th><p style={{ fontWeight: 'bold' }}>{row.diagnosis_klinis}</p></th>
                    </tr>
                    <tr style={{ display: row.petugas == null ? 'none' : null }}>
                        <th>PETUGAS</th>
                        <th><p style={{ fontWeight: 'bold' }}>{row.petugas} - {row.branch_name}</p></th>
                    </tr>
                </table>

                <table style={{ border: '1px solid #ddd', backgroundColor: '#D6EEEE', marginBottom: 10, textAlign: 'left' }}>
                    <tr>
                        <th style={{}}><p style={{ fontWeight: "bold" }}>DETAIL PEMERIKSAAN</p></th>
                    </tr>
                    <tr>
                        <th>
                            <ul
                                style={{
                                    listStyleType: "number",
                                    lineHeight: "24px",
                                    padding: "0px 20px 0px 20px",
                                }}
                            >
                                {JSON.parse(row.checkout).map(function (x) {
                                    return <>

                                        <li> {x.desc + " - " + numberFormat(x.price)} </li>
                                        {x.new_desc == "" ? <p> {getKeyByValue(JSON.parse(row.test), x.id)} </p> : <p>{x.new_desc} </p>}
                                    </>;
                                })}
                            </ul>
                        </th>
                    </tr>
                </table>

                <table style={{ border: '1px solid #ddd', backgroundColor: '#a0d8d8' }}>
                    <tr>
                        <th>Total:</th>
                        <th><p style={{ fontWeight: 'bold' }}>{row.total_harga}</p></th>
                    </tr>
                </table>


                {/* <div style={{ marginTop: 10, marginBottom: 10 }}>


                    <div className="row" style={{ marginTop: "20px", marginBottom: 10 }}>
                        <div
                            className="col-sm-4 col-form-label confirm_label"
                            style={{
                                paddingBottom: "2px",
                            }}
                        >
                            <p>Scan QR Code:</p>
                            <div style={{ visibility: 'hidden', height: 0 }}>
                                <QRCode
                                    id={row.patient_id}
                                    value={row.patient_id}
                                    size={600}
                                    level={"H"}
                                    fgColor="#22485e"
                                    imageSettings={{
                                        src: logo_48,
                                        width: 130,
                                        height: 130,
                                        excavate: true,
                                    }}
                                />
                            </div>

                            <QRCode
                                id="qr-gen"
                                value={row.patient_id}
                                size={130}
                                level={"H"}
                                fgColor="#22485e"
                                imageSettings={{
                                    src: logo_,
                                    width: 30,
                                    height: 30,
                                    excavate: true,
                                }}
                            />
                            <p
                                style={{
                                    fontFamily: "var(--font-family-gotha-boo)",
                                    fontSize: "9.2px",
                                    marginTop: "10px",
                                }}
                            >
                                <button
                                    className="btn btn-sm btn-primary py-0"
                                    type="button"
                                    style={{ fontSize: "0.8em" }}
                                    onClick={(e) => downloadQRCode(row.patient_id)}
                                >
                                    Download QR Code
                                </button>
                                <button
                                    className="btn btn-sm btn-primary py-0"
                                    type="button"
                                    style={{ fontSize: "0.8em", marginLeft: "4px" }}
                                    onClick={(e) => shareQRCode(row.patient_id)}
                                >
                                    Share
                                </button>
                            </p>



                        </div>




                    </div>


                </div> */}

                <div style={{ float: 'left', marginTop: 10, marginBottom: 10 }}>
                    <button
                        className="btn btn-sm btn-outline-primary py-0"
                        type="button"
                        style={{ fontSize: "1em", marginRight: 4, display: row.status == "SELESAI" || JSON.parse(localStorage._log).level == 2 ? 'none' : null }}
                        // onClick={downloadQRCode}
                        onClick={e => onClickSelesai(e, row.id)}
                    >
                        Selesai {row.status}
                    </button>

                    <button
                        className="btn btn-sm btn-outline-primary py-0"
                        type="button"
                        style={{ fontSize: "1em" }}
                        // onClick={downloadQRCode}
                        onClick={(e) => goPrint(row)}
                    //  onClick={(e) => console.log("ini yang akan di print", row)}
                    >
                        Cetak Kuitansi
                    </button>
                </div>


            </div>
        ),
        showExpandColumn: true,
        // style: () => {
        //     return {
        //         width: "10px",
        //         textAlign: 'center',
        //     };
        // },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return <b>-</b>;
            }
            return <b>+</b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return <b>-</b>;
            }
            return <b>+</b>;
        },
    };

    const getKeyByValue = (object, value) => {
        var x = Object.keys(object);
        var y = [];
        x.map((el, i) => {
            if (object[el].find(z => z.id == value) != undefined) {
                y.push(object[el].find(z => z.id == value).new_desc)
            }
        })
        // console.log("ini z",y[0]);
        return y[0];
    };


    let pasien_ = [];
    async function cek_all_data() {
        // alert("ini jalan");
        let url = "";

        if (localStorage._log) {
            if (JSON.parse(localStorage._log).level == 2) {
                url = `/api/v1/getAllRujukan/` + userID + `?idp=${JSON.parse(localStorage._log).idp}`
            } else {
                url = "/api/v1/getAllRujukan/0" + `?idp=${JSON.parse(localStorage._log).idp}`;
            }
        } else {
            window.location.reload()
        }

        instance.defaults.headers.post["X-CSRF-Token"] = CSRFToken;
        await instance
            .get(uAPI + url)
            .then((response) => {
                // alert(response.data["results"].length)
                console.log("ini response", response.data["results"])
                response.data["results"].map(function (key) {

                    // var z = getKeyByValue(JSON.parse(key.test), "2");
                    // console.log("ini key", z)
                    pasien_.push({
                        id: key.id_pasien,
                        nama_pasien: key.nama_pasien,
                        tgl_lahir: moment(key.tgl_lahir).format("DD-MM-YYYY"),
                        no_telp: key.no_telp,
                        patient_id: key.patient_id,
                        alamat: key.alamat,
                        email: key.email,
                        checkout: key.checkout,
                        //   tgl_periksa: moment(key.tgl_periksa).format("DD-MM-YYYY"),
                        tgl_periksa: moment(key.tgl_periksa).format("YYYY-MM-DD"),
                        total_harga: numberFormat(key.total_harga),
                        test: key.test,
                        nama_user: key.nama_user,
                        nama_perusahaan: key.nama_perusahaan,
                        email: key.email,
                        alamat: key.alamat,
                        no_telp: key.no_telp,
                        status_pembayaran: key.status_pembayaran,
                        catatan: key.catatan,
                        status: key.status,
                        diagnosis_klinis: key.diagnosis_klinis,
                        petugas: key.petugas,
                        branch_name: key.branch_name,
                        created_date: key.created_date,
                        type: key.type
                    });
                });
            })
            .catch((error) => {
                console.log(error);
            });
        updatePasien();
    }

    function onClickSelesai(e, v) {
        // console.log("ini edisi", e.target + "---" + v)
        // alert(v);
        updateStatus(v)
    }



    const updatePasien = () => {
        setPasien(pasien_);
    };

    async function updateStatus(id) {
        let url = `/api/v1/updateStatus/` + id + `?ptgs=${loginName}` + `&b=${branchName}`
        instance.defaults.headers.post["X-CSRF-Token"] = CSRFToken;
        await instance
            .get(uAPI + url)
            .then((response) => {
                console.log("ini response", response.data["results"]);
                alert("Status berhasil diubah");
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {(() => {
                if (redirctTo) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                login_alert: {
                                    msg: "Mohon login terlebih dahulu!",
                                    status: "logout",
                                },
                            }}
                        />
                    );
                }
            })()}

            <Header />

            <div
                className="container-fluid"
                style={{ fontFamily: "var(--font-family-gotha-boo)" }}
            >
                <div className="row no-gutter">
                    <div className="col-md-12">
                        {pasien === undefined ? null : (
                            <div
                            //    style={{ height: 400, width: "100%", padding: "20px" }}
                            >
                                {/* <button className="btn btn-lg btn-primary" onClick={handleClick}>
         {" "}
         Clear all filters{" "}
        </button> */}

                                <BootstrapTable
                                    classes="normal-text"
                                    headerWrapperClasses="table_"
                                    bordered={true}
                                    keyField="patient_id"
                                    data={pasien}
                                    columns={columns}
                                    expandRow={expandRow}
                                    pagination={paginationFactory()}
                                    filter={filterFactory()}
                                //  defaultSorted={ defaultSorted }
                                // striped
                                />
                            </div>
                        )}

                        <br />


                        <div style={{ marginTop: "20px", display: "none" }}>
                            <ComponentToPrint ref={componentRef} />
                        </div>



                    </div>
                </div>
            </div>
        </>
    );
}
